import React from 'react'
import { Box } from '@theme-ui/components'
import Link from 'gatsby-link'
import generateLink from '../../helpers/generateLink'

export default function InternalLink({ ctaClassName, variant, ctaName, ctaLink, isMultiLocationSite, city }) {
  return (
    <Link
      className={`gatsby-link ${ctaClassName ? ctaClassName : ''}`}
      to={generateLink(ctaLink, isMultiLocationSite, city)}
    >
      <Box as="span" variant={`${variant}.ctaButton`} className="ctaButton">
        {ctaName}
      </Box>
    </Link>
  )
}
